<template>
    <a-card>
        <a-page-header
            :title="id == 0 ? '新建创意标签模板' : '创意标签模板详情'"
        />

        <a-form-model
            ref="form"
            :model="form"
            :rules='rules'
            v-bind='layout'
        >
            <a-form-model-item label="标签适用范围" prop='scope'>
                <a-radio-group v-model="form.scope" @change="onChangeScope" v-if="id == 0">
                    <a-radio :value="1">车系</a-radio>
                    <a-radio :value="2">品牌</a-radio>
                </a-radio-group>
                <span v-else>{{ form.scope == 1 ? '车系' : '品牌' }}</span>
            </a-form-model-item>
            <a-form-model-item label="品牌车系" prop="principalId" style="margin-bottom: 0">
                <a-select
                    v-model="form.principalId"
                    placeholder="请选择品牌"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    @change="onChangePrincipal"
                    v-if="id == 0"
                >
                    <a-select-option
                        v-for="item in principalList"
                        :key="item.id"
                    >
                        {{ item.principal_name }}
                    </a-select-option>
                </a-select>
                <span v-else>{{ form.scope == 1 ? form.carSeriesName || '-' : form.principalName || '-' }}</span>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false" prop="carSeriesId" v-if="id == 0 && form.scope != 2">
                <a-select
                    v-model="form.carSeriesId"
                    placeholder="请选择车系"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    :disabled="!form.principalId"
                >
                    <a-select-option
                        v-for="item in seriesList"
                        :key="item.id"
                    >
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <div v-for="(item, index) in form.creativeLabelArr" :key="index">
                <a-form-model-item
                    :label="'标签' + (index + 1)"
                    ref="creativeLabelArr"
                    prop="creativeLabelArr"
                    v-if="index == 0"
                    style="margin-top: 24px;"
                >
                    <div class="point-box">
                        <wordLimitInput 
                            :value="form.creativeLabelArr[index]"
                            :maxLength="10"
                            @onChangeInput="e => onChangeInput(e, 'creativeLabelArr', index)"
                        ></wordLimitInput>
                        
                        <a-icon type="plus-circle" class="icon plus" @click="onChangePoint('plus', index)" v-if="form.creativeLabelArr.length < 20" />
                    </div>
                    <div class="txt">最多支持20个标签</div>
                </a-form-model-item>
                <a-form-model-item
                    :label="'标签' + (index + 1)"
                    v-else
                >
                    <div class="point-box">
                        <wordLimitInput 
                            :value="form.creativeLabelArr[index]"
                            :maxLength="10"
                            @onChangeInput="e => onChangeInput(e, 'creativeLabelArr', index)"
                        ></wordLimitInput>
                        <a-icon type="minus-circle" class="icon minus" @click="onChangePoint('minus', index)" />
                    </div>
                </a-form-model-item>
            </div>

            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
import wordLimitInput from '@/components/wordLimitInput'

export default {
    components: {
        wordLimitInput
    },
    data() {
        let checkCarSeriesId = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请选择车系'))
            } else {
                callback();
            }
        }
        let checkProductSellingPointArr = (rule, value, callback) => {
            if (!this.form.creativeLabelArr[0]) {
                callback(new Error('请填写标签'))
            } else {
                callback();
            }
        }
        
        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            form: {
                scope: 1,
                principalId: undefined,
                carSeriesId: undefined,
                creativeLabelArr: [''],
            },
            rules: {
                scope: [
                    { required: true, message: '请选择标题适用范围', trigger: 'change' }
                ],
                principalId: [
                    { required: true, message: '请选择品牌', trigger: 'change' }
                ],
                carSeriesId: [
                    { validator: checkCarSeriesId, trigger: 'change' }
                ],
                creativeLabelArr: [
                    { required: true, validator: checkProductSellingPointArr, trigger: 'change' }
                ],
            },
            isSubmit: false,
            principalList: [],
            seriesList: [],
        }
    },
    created() {
        this.getPrincipalList()
        if(this.id != 0) {
            this.getDetail()
        }
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangeScope(e) {
            this.form.carSeriesId = undefined
            if(e.target.value == 1) {
                this.getPrincipalSeriesList(this.form.principalId)
            }
        },
        onChangePrincipal(id) {
            this.form.carSeriesId = undefined
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: [id],
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        onChangeInput(val, type, index) {
            if((index ?? '') !== '') {
                this.$set(this.form[type], index, val)
                index == 0 ? this.$refs[type][index].onFieldChange() : ''
            } else {
                this.form[type] = val
                this.$refs[type].onFieldChange()
            }
        },
        onChangePoint(type, index) {
            if(type === 'plus') {
                this.form.creativeLabelArr.push('')
            } else {
                this.form.creativeLabelArr.splice(index, 1)
            }
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/creativeTagManage'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    if(this.form.scope == 2) {
                        this.form.carSeriesId = undefined
                    }

                    let body = {
                        data: this.form,
                        type: this.id == 0 ? 'add' : 'edit'
                    }
                    this.$api.core.materialManage.creativeTagTemplate( body ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('提交成功')
                            this.$router.push({
                                path: '/creativeTagManage'
                            })
                        } else {
                            this.$message.error(`提交失败，${res.message}`)
                        }
                    })
                }
            })
        },
        getDetail() {
            this.$api.core.materialManage.getTagTemplateDetail(this.id).then(res => {
                if(res.code == 200) {
                    let _data = res.data
                    this.form = _data

                    if(_data.scope == 1) {
                        this.getPrincipalSeriesList(_data.principalId)
                    }
                } else {
                    this.$message.error(`获取详情失败，${res.message}`)
                }
            })
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.point-box {
    position: relative;
    .icon {
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(60px, -50%);
        font-size: 22px;
        line-height: 0;
        cursor: pointer;
        &.plus {
            color: green;
        }
        &.minus {
            color: red;
        }
    }
}
.txt {
    margin-top: 7px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 1.5;
}
</style>